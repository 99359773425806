import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/layouts/text.tsx";
import thumbsup from '@assets/thumbsup-emoji.png';
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className='sidebar-layout'>
      <div className='sidebar-layout__main' markdown='1'>
        <p>{`Do you think something is broken with FullCalendar's existing behavior? If so, please carefully follow these steps for reporting a bug:`}</p>
        <h2>{`Step 1: Post a reduced test case`}</h2>
        <p>{`A bug cannot be fixed if it cannot be recreated on the project dev's end. A
link to a bug recreation with your application-specific code stripped out, known as a "`}<a parentName="p" {...{
            "href": "https://css-tricks.com/reduced-test-cases/"
          }}>{`Reduced Test Case`}</a>{`",
is `}<strong parentName="p">{`absolutely manditory of you want your bug fixed.`}</strong></p>
        <Link to='/reduced-test-cases' mdxType="Link">How to create a reduced test case &raquo;</Link>
        <p>{`Copy-and-pasted blocks of code that are not runnable are NOT acceptable.`}</p>
        <h2>{`Step 2: Describe the bug`}</h2>
        <p>{`Please describe the correct desired behavior and how the buggy behavior differs from that. Please describe, in detail,
the exact steps that are needed to reproduce the bug.`}</p>
        <p><em parentName="p">{`Screenshots`}</em>{` are extremely helpful. For bugs that require complicated user interaction, please post a recorded video screencast if possible.`}</p>
        <h2>{`Step 3: Use the issue tracker`}</h2>
        <p>{`At this point, anyone should be able to open up your JSFiddle demonstration, read your description, and `}<strong parentName="p">{`reproduce your bug in less than 1 minute!`}</strong></p>
        <p>{`Please enter only one bug per ticket and do not combine tickets.`}</p>
        <p><a parentName="p" {...{
            "href": "https://github.com/fullcalendar/fullcalendar/issues"
          }}>{`Visit the issue tracker `}{`»`}</a></p>
        <p>{`It is a good idea to search through the list to see if someone has already reported your bug. If so,
`}<strong parentName="p">{`vote with a`}</strong>{` `}<img src={thumbsup} width="20" height="20" style={{
            "verticalAlign": "baseline"
          }} alt='thumbsup' />{` reaction
on the topmost description of the issue (`}<a parentName="p" {...{
            "href": "https://github.com/blog/2119-add-reactions-to-pull-requests-issues-and-comments"
          }}>{`help`}</a>{`) and
press the `}<strong style={{
            "backgroundImage": "linear-gradient(#fcfcfc, #eee)",
            "border": "1px solid #d5d5d5",
            "color": "#333",
            "padding": "0 5px",
            "borderRadius": "3px"
          }}>{`Subscribe`}</strong>{` button
to receive updates when progress is made.`}</p>
      </div>
  <div className='sidebar-layout__sidebar'>
    <div className='sidebar-card'>
      <h3>Premium Support</h3>
      <p>FullCalendar Premium comes with 1 year of email support.</p>
      <Link to='/pricing' className='button button--filled button--blue' mdxType="Link">
        Learn more &raquo;
      </Link>
    </div>
  </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      